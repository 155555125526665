<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Privacy Policy</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="terms-conditions-area pt-50 pb-70">
    <div class="container">
        <div class="single-content">
 
            <h2>Welcome to B2BeFax Privacy Policy</h2>
            <p>In this Privacy Policy (<strong>"Policy“</strong>), references to <strong>“We”, “Us” or “Our”</strong> means <strong>Gventure Communication, HongKong</strong>. This Privacy Policy shall govern Our use of any data collected by Us concerning your use of b2befax.com and the Services (<strong>” Our Site“</strong>). The use of information collected through Our Site shall be limited to the purposes under this Policy.</p>
            <p>Gventure Communication understands that your privacy is important to you and that you care about how your information is used and shared online. We respect and value the privacy of everyone who visits Our Site and will only collect and use information in ways that are useful to you and in a manner consistent with your rights and Our obligations under the law. Please read this Privacy Policy carefully and ensure that you understand it. If you have any questions regarding this Privacy Policy, the practices of this website or your dealings with this website, you may contact us at <a href="mailto:legal@gventurecommunication.com" style="color: blue; text-decoration: underline;">legal@gventurecommunication.com</a></p>
        </div>
        <div class="single-content">
            <h4></h4>
				
			<ol>
			<li><h4>Definitions and Interpretation</h4>
			<p>In this Policy the following terms shall have the following meanings: </p>
			<p><strong>Account :</strong> means an account required to access and/or use certain areas and features of Our Site or Services </p>
			<p><strong>Data Protection Laws :</strong> means applicable data protection laws in connection with this Privacy Policy. </p>
			<p><strong>Cookie :</strong> means a small text file placed on your computer or device by Our Site when you visit certain parts of Our Site and/or when you use certain features of Our Site. Details of the Cookies used by Our Site are set out in section 13, below </p>
			<p><strong>Controller :</strong> means the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the Processing of Personal Information. </p>
			<p><strong>Customer :</strong> means the entity that has subscribed to Our Services by agreeing to the terms of service at https://b2befax.com/terms/ </p>
			<p><strong>Data Subject :</strong> means any identified or identifiable natural person. </p>
			<p><strong>Our Site :</strong> means this website, b2befax.com </p>
			<p><strong>Personal Information :</strong> means personally identifiable information </p>
			<p><strong>Process :</strong> means any operation or set of operations which is performed on Personal Information or sets of Personal Information, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction. </p>
			<p><strong>Services :</strong> means the cloud-based telecommunication services provided by Gventure Communication that the Customer has subscribed to under the Terms. </p>
			<p><strong>Standard Contractual Clauses” / “Model Clauses :</strong> means the standard contractual clauses for data Controller to data Controller transfers as approved by the European Commission in decision 2004/915/EC, as available at: https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32004D0915 (as amended or updated from time to time.) </p>
			<p><strong>UK and EU Cookie Law :</strong> means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended in 2004, 2011 and 2015 </p>
			<p><strong>We/Us/Our :</strong> means Gventure Communication </p>
			
			</li>
			<li>
				<h4>What Does This Policy Apply To?</h4>
				<p>We gather Personal Information when you utilize the Site and its accompanying Services, as well as during the regular course of our operations. This policy outlines our procedures for collecting and utilizing Personal Information, as well as your entitlements concerning our utilization of your Personal Information.
				Please note that this Privacy Policy exclusively pertains to your interaction with our Site and does not encompass any websites linked to from our Site, whether those links are provided by us or shared by other users. We lack authority over the methods by which your data is acquired, stored, or utilized by other websites or services. We strongly encourage you to review the privacy policies of such websites before furnishing any information to them.
				</p>
			</li>
			
			<li>
				<h4>What Information Do We Collect?</h4>
				<p>Our Site collects Personal Information in two ways: automatic collection (as detailed in section 12) and voluntary submission. For instance, when you create an Account, you may voluntarily provide certain Personal Information. Depending on how you use Our Site, we may collect some or all of the following Personal Information: Name, date of birth, gender, business/company name, job title, profession, contact details like email addresses and phone numbers, demographic information such as postal code, preferences, interests, financial information including credit/debit card numbers, IP address (collected automatically), web browser type and version (collected automatically), operating system (collected automatically), a list of URLs starting from a referring site, your activity on Our Site, and the site you exit to (collected automatically).</p>
				<p>Furthermore, when using Our Services, Gventure Communication may request permission to access the contacts on your phone. This access is required in two scenarios:  </p>
				<p>	When you intend to make a call or send a text directly from the Gventure Communication app, Gventure Communication will seek your permission to access your phone contacts. </p>
				<p>	When making a call to a phone contact, you will be presented with a list of apps to complete the action. Gventure Communication is one of the options. If you choose Gventure Communication, it will gain access to your contacts </p>
				<p>Additionally, while using Our Services, Gventure Communication will function as an independent Controller when processing specific types of your Personal Information, such as Customer Usage Data and Customer Subscription Data, as defined in Appendix I of this Policy.</p>
			
			</li>
			
			<li><h4>How Is Your Personal Information Used?</h4>
				<p>We will utilize your Personal Information exclusively in accordance with the guidelines outlined in this Privacy Policy and will only process your Personal Information if we have a valid legal basis for doing so, as expounded upon in section 5. Your Personal Information may be employed for the following purposes:</p>
				<ul>
					<li>To facilitate and oversee your Account. </li>
					<li>To administer and supervise your access to Our website. </li>
					<li>To tailor your preferences to enhance your experience on Our website. </li>
					<li>To respond to your communications. </li>
					<li>To conduct market research </li>
					<li>To analyze your usage of Our website. </li>
					<li>To collect feedback, enabling us to continually enhance Our website and your user experience.</li>
				</ul>
				<p>Additionally, subject to your consent and in compliance with applicable laws, we may also employ your Personal Information for marketing purposes. This may encompass reaching out to you via email, telephone, text message, postal mail, or web push notifications to provide information, updates, and offers related to Our products and services. Rest assured, we are committed to refraining from sending you any unsolicited messages and will diligently adhere to relevant regulations in this regard. </p>
				<p>Furthermore, it's worth noting that advertisers whose content is displayed on Our Site may engage in "behavioral advertising." This form of advertising is tailored to your preferences based on your online activity and is accomplished through the use of Cookies, as elaborated upon in section 12. You have the ability to control and limit the utilization of your data for this purpose by adjusting your web browser's privacy settings. It's important to acknowledge that we do not have control over the actions of these advertisers or the information they gather and utilize. While limiting the use of your Personal Information in this manner won't eliminate the advertising, it will render it less aligned with your interests and activities on Our Site.
			</li>
			<li><h4>Legal Basis</h4>
				<p>If you are a Data Subject from the European Economic Area, the basis upon which we collect and utilize the Personal Information mentioned earlier will vary depending on the specific Personal Information involved and the particular circumstances under which we acquire it. Generally, we will gather Personal Information from you when it is necessary to fulfill a contractual obligation with you, when the processing aligns with our legitimate interests, provided such interests do not override your data protection rights or fundamental freedoms, or when we have obtained your consent. There are situations where we may also be obligated by law to collect your Personal Information. Should we process your Personal Information based on your consent, you retain the right to withdraw that consent at any time. If you have inquiries or require additional information regarding the legal grounds for our collection and use of your Personal Information, please do not hesitate to contact us using the contact details specified in Section 13.</p>
			</li>
			<li><h4>When Do We Share Your Personal Information?</h4>
				<ul>
					
					<li>We may share your Personal Information with other companies in Our group. This includes Our subsidiaries AND/OR Our holding company and its subsidiaries. </li>
					<li>We may contract with third parties to supply products and Our services to you on Our behalf. These may include billing and payment processing, search engine facilities and advertising and marketing. In some cases, the third parties may require access to some or all of your Personal Information. Where any of your Personal Information is required for such a purpose, We will take all reasonable steps to ensure that your Personal Information will be Processed by such third-party in compliance with applicable laws. </li>
					<li>We may compile statistics about the use of Our Site including data on traffic, usage patterns, user numbers, sales and other information. All such data will be anonymised and will not include any Personal Information. We may from time to time share such data with third parties such as prospective investors, affiliates, partners and advertisers. Data will only be shared and used in compliance with applicable laws. </li>
					<li>With your permission and/or as permitted under law, We may share your Personal Information with third parties who may send you marketing collateral in connection with the Services. </li>
					<li>In certain circumstances, We may be legally required to share certain data held by Us, which may include your Personal Information, for example, where We are involved in legal proceedings or where We are complying with the requirements of legislation, a court order, or a governmental authority. We do not require any further consent from you to share your data in such circumstances and will comply as required with any legally binding request that is made of Us. </li>
					<li>We may also share your Personal Information if We merge with or are acquired by another company. In such a case, your information will likely be one of the assets that is transferred. </li>
					
				</ul>
			</li>
			<li><h4>What Happens When You Connect Your Email Account or Sign Up Via Social Media?</h4>
				<ul>
					<li>You have the option to explicitly grant consent for the connection of your email accounts to your Account. Once this connection is established, we will securely access and analyze the relevant content related to the Services we offer. Before signing in through the applicable service, we recommend reviewing your Personal Information.</li>
					<li>Please be assured that we do not, under any circumstances, retain your email messages, email message headers, message bodies, or attachments. Our access to your email account is solely for the purpose of scanning, and we immediately display information as it is retrieved. We request read-only access to your email account and will not make any attempts to modify or alter your email account or email messages unless you provide additional consent for write permission specific to the Services you are selecting.</li>
					<li>Additionally, our website includes social media features and widgets that are hosted by third parties or directly on our site. Your interactions with these social media features and widgets are subject to the privacy policies of the companies that provide them. We recommend checking your privacy settings on these third-party services to understand and control the information transmitted to us through these Services. This data may be combined with other information we collect and may include aggregated data.</li>
				</ul>
			</li>
			<li><h4>How Is Your Personal Information Stored and Transferred?</h4>
				<ul>
					<li>Your personal information and associated files, along with their backups, are securely stored on our servers and on servers operated by companies we contract to provide services to us. These servers are located both in the United States of America and India. Additionally, the companies we engage with to assist in our business operations may be situated in various countries across the globe. Please be aware that the data we gather from you may be transmitted to and stored in countries outside of the European Economic Area (EEA). Furthermore, it may undergo processing by personnel operating outside the EEA who are affiliated with either our organization or our partners.</li>
					<li>If you are a resident of the European Economic Area, it is important to note that when your personal information is processed outside of the EEA, we are committed to ensuring that the entity receiving your personal information adheres to a high standard of data protection. This can be achieved through methods such as the adoption of standard contractual clauses approved by the European Commission (as outlined in Article 46 of the General Data Privacy Regulation, 2016) or by obtaining your prior consent for such international data transfers.</li>
					<li>In cases where we act as an independent controller for customer usage data and customer subscription data, we incorporate the Standard Contractual Clauses approved by the European Commission in decision 2004/915/EC into this policy by reference. Schedule 1 of this policy replaces Annex B of the Standard Contractual Clauses. For the purpose of describing the roles in the Model Clauses, it is agreed that Gventure Communication is considered the "data importer," while you are the "data exporter." In this context, "Personal Data" is to be interpreted in the same manner as "Personal Information" as defined in Clause 1 of this policy.</li>
				</ul>
			</li>
			<li><h4>How Is Your Personal Information Stored and Transferred?</h4>
			<p>Gventure Communication will maintain the Personal Information it gathers when a continuing legitimate business need demands such retention of such Personal Information. This may include requirements for litigation or defense, as well as fulfilling legal obligations, preserving accurate financial and other records, resolving disputes, and upholding our agreements. If there is no longer a necessity to retain your Personal Information as outlined above, we will take one of the following actions: either delete it or aggregate it. If neither of these options is feasible, we will securely store your Personal Information and isolate it from any further processing until deletion becomes possible.</p>
			
			</li>
			<li><h4>Rights of Children</h4>
				<p>We emphasize the significance of safeguarding the safety and privacy of children. We neither request nor knowingly gather any personally identifiable information from individuals under the age of 16. If a parent or guardian becomes aware that their child has provided us with Personal Information, they should reach out to us via the email address specified in Section 15 of this Policy.</p>
			</li>
			<li><h4>How We Protect Your Personal Information</h4>
				<ul>
					<li>We place utmost importance on the security of your Personal Information. We employ suitable technical and organizational measures to safeguard the Personal Information we gather and process. These measures are designed to offer an appropriate level of security commensurate with the risk associated with processing your Personal Information. Should you have any inquiries about the security of your Personal Information, please get in touch with us promptly as explained in this Policy.</li>
					<li>Despite the security measures we implement, it's crucial to bear in mind that data transmission over the internet may not be entirely secure. We recommend taking appropriate precautions when sending data to us via the internet.</li>
				</ul>
			</li>	
			<li><h4>Summary of Your Rights Under Gdpr</h4>
				<p>You possess the following entitlements regarding your Personal Information:</p>
				<p><strong>Right to Information:</strong> You have the right to be informed about whether we hold your Personal Information and the purpose for which we process it.</p>
				<p><strong>Right of Access:</strong> You have the right to obtain a copy of your Personal Information. This includes an explanation of the categories of Personal Information being processed, the purposes of such processing, and details about third parties to whom your Personal Information may have been disclosed.</p>	
				<p><strong>Right to Rectification:</strong> You are entitled to correct or update the Personal Information we have on record about you.</p>
				<p><strong>Right to Erasure:</strong> You have the right to request the erasure of your Personal Information from our customer relationship management (CRM) databases. It's important to note that we may need to retain certain Personal Information for legal and internal business purposes, such as fraud prevention. We will keep your Personal Information for as long as necessary to provide you with eligible website and app access and to comply with our legal obligations and agreements.</p>
				<p><strong>Right to Data Portability:</strong> You can obtain and reuse your Personal Information. You may either obtain it from us or request us to transfer it directly to a third party of your choice.</p>
				<p><strong>Right to Object/Restrict Processing:</strong> You have the right to object to or restrict the processing of your Personal Information in certain situations. This includes instances where your Personal Information is inaccurate (during the verification process), no longer necessary for the intended purpose of processing, or in the context of direct marketing (you can opt out of marketing communications by checking specific boxes on our data collection forms or using opt-out options in our emails). You also have the right not to be subject to automated decision-making, including profiling, that has significant legal or similar effects on you.
In cases where you exercise any of these rights, we will retain a minimal amount of Personal Information to ensure that you are not contacted again. To exercise any of these rights, please contact us at the email address provided in section 13.</p>
			</li>
			<li><h4>Cookie Policy</h4>
				<p>During your visit or use of our website, we may install certain cookies on your device. To learn more about this, please refer to our Cookie Policy.</p>
			</li>
			<li><h4>Contacting Us</h4>
			<p>If you have any questions about Our Site or this Privacy Policy, please contact us by email at legal@b2befax.com.</p>
			<p>Please ensure that your query is clear, particularly if it is a request for information about the data We hold about you.</p>
			</li>
			<li><h4>Changes to Our Privacy Policy</h4>
			 <p>We reserve the right to modify this Privacy Policy as deemed necessary or as required by law. Your ongoing use of Our Services following any changes or updates to this Privacy Policy signifies your acceptance of the revised terms. We advise you to regularly review this page to stay informed of any updates.</p>
			</li>
			<li><h4>Notice to End-User and Other Exclusions</h4>
			<p>If you engage with a Customer through Our Services, it means that the Customer becomes the Controller of your Personal Information. If you have any questions or requests regarding your data privacy, please direct them to the Customer, who acts as your Controller. They will guide you and provide assistance with any concerns about your Personal Information. It's important to note that our responsibility does not extend to the privacy or security practices of our Customers, which may differ from this policy.</p>
			<p>Unless explicitly stated in this policy, it does not apply to the usage of Our Services. The security and privacy procedures, including how we safeguard, gather, and utilize electronic data, text, messages, communications, or other content submitted to and stored within the Services by a Customer, are outlined and governed by the terms of service provided by Gventure Communications or any other relevant agreement between the Customer and us pertaining to the access and utilization of such Services.</p>
			</li>
			</ol>
            
        </div>
        <div class="single-content">
			<h4>APPENDIX – I</h4>
			<h6>ANNEX B TO THE STANDARD CONTRACTUAL CLAUSES</h6>
			<p>Transfer Description: Data Subjects: The transferred Personal Data pertains to the following categories of individuals:</p>
			<p>Data exporter and data exporter's end users.</p>
			<p>Purposes of the Transfer(s): The transfer is executed for the following objectives:</p>
			<p>Provision of Gventure Communication's business telecommunication services.</p>
			<p>Categories of Data: The Personal Data transferred encompasses the following data categories:</p>
			<p>Customer Subscription Data: This includes Personal Data linked to the Customer's interactions and engagement with Gventure Communication. It encompasses the names and contact details of individuals authorized by the Customer to access their Account, as well as billing information associated with individuals linked to the Customer's Account. Customer Subscription Data also comprises any information required by Gventure Communication for identity verification and other legal obligations.</p>
			<p>Customer Usage Data: This data pertains to the activity within the Customer's Account, processed by Gventure Communication for the purpose of delivering Customer Content to its intended recipients. It encompasses information used to identify the source and destination of Customer Content, such as individual Data Subjects' telephone numbers, date, time, duration, communication type, and device location data generated while providing the services. Additionally, it includes communication logs used for source identification of service requests, system abuse prevention, and improvement.</p>
			<p>Recipients: The Personal Data transferred may exclusively be disclosed to the following recipients or categories of recipients:</p>
			<p>Employees, agents, affiliates, advisors, and independent contractors of the data importer, with a reasonable and legitimate business requirement for accessing such Personal Data.</p>
			<p>Vendors of the data importer who, in fulfilling their obligations to the data importer, need to process such Personal Data on behalf of and in accordance with the data importer's instructions.</p>
			<p>Any individual (natural or legal) or organization to whom the data importer may be legally obliged to disclose Personal Data, as mandated by applicable laws or regulations. This includes law enforcement authorities, central and local government agencies.</p>
			<p>Sensitive Data: Not applicable.</p>
			<p>Data Protection Registration of the Data Exporter: As registered by the data exporter</p>
			<p>Contact Points for Data Protection Enquiries:
			<ul>
			<li>Data Importer: legal@b2befax.com</li>
			<li>Data Exporter: The email ID provided at the time of signing up.</li>
			</ul>
		
			<p></p>
			
			<p></p>
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/privacypolicy.png" alt="Images">
                </div>
                
                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/privacypolicy(2).png" alt="Images">
                </div>

                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/privacypolicy(3).png" alt="Images">
                </div>

                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/privacypolicy(4).png" alt="Images">
                </div>

                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/privacypolicy(5).png" alt="Images">
                </div>

                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/privacypolicy(6).png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>