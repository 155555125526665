import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    // Set the title
    this.titleService.setTitle('Bulk faxing - Fax Solutions Blog By B2B E-Faxing');

    // Set meta tags
    this.metaService.updateTag({ name: 'description', content: 'Explore the benefits and strategies of bulk faxing in our Fax Solutions Blog. Learn how B2B e-faxing can streamline your business communication, increase efficiency, and reach a wider audience.' });
    this.metaService.updateTag({ name: 'keywords', content: 'bulk faxing, fax solutions blog, B2B e-faxing, business communication, efficiency, wider audience' });

  }

}
