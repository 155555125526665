import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
      // Set the title
      this.titleService.setTitle('About us - B2B fax broadcasting solutions');

      // Set meta tags
      this.metaService.updateTag({ name: 'description', content: 'Our mission is to empower businesses with innovative fax broadcasting solutions, facilitating seamless communication, enhancing efficiency, and ensuring reliable, customer-focused services.' });
      this.metaService.updateTag({ name: 'keywords', content: 'fax blast, medical fax, fax healthcare, fax insurance, legal fax, fax finance, fax broadcasting services' });
  }

}




