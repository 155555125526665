<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container-max-2">
        <div class="row inner-title text-center">
            <div class="col-lg-5 col-md-12">
                <div class="banner-content-two">
                    <div class="banner-content-btn" style="margin-top: -50px;">
                        <a href="#" class="learn-btn">Learn More <i class='bx bx-plus'></i></a>
                        <a href="https://www.youtube.com/watch?v={{otherdetails.youtube}}" class="play-on-btn popup-btn">Play Video <i class='flaticon-forward'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <h3>{{service}}</h3>
                <ul>
                    <li><a href="index.html">Home</a></li>
                    <li><i class='bx bx-chevron-right'></i></li>
                    <li>{{service}}</li>
                    <li><i class='bx bx-chevron-right'></i></li>
                    <li>{{otherdetails.industry}}</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/{{otherdetails.image1}}" alt="Banner-for-{{otherdetails.industry}}">
                        <h2>{{mainContent.title}}</h2>
                        <p>{{mainContent.description}}</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6" *ngFor="let row of mainContent.content">
                            <div class="services-content-card">
                                <h3>{{row.title}}</h3>
                                <p>{{row.description}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/{{otherdetails.image2}}" alt="image-for-{{otherdetails.industry}}">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>{{subContent.title}}</h2>
                                    <p>{{subContent.description}}</p>
                                    <ul>
                                        <li *ngFor="let row of subContent.highlight"><i class='bx bx-check'></i>{{row.topic}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>{{subContent.othercontent}}</p>

                    <!--<div class="services-widget-list">
                        <ul>
                            <li class="active">
                                <span>75%</span>
                                <div class="content list1">
                                    <h3>SEO & Marketing</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Keywords Results</h3>
                                </div>
                                <span>66%</span>
                            </li>
                            <li>
                                <span>43%</span>
                                <div class="content list3">
                                    <h3>Google Analytics</h3>
                                </div>
                            </li>
                            <li>
                                <div class="content list2">
                                    <h3>Off Page SEO</h3>
                                </div>
                                <span>15%</span>
                            </li>
                        </ul>
                    </div>-->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <!--<div class="widget-category">
                        <h3>Other Services</h3>

                        <ul>
                            <li><a routerLink="/services-details">AI & ML Development</a></li>
                            <li><a routerLink="/services-details">Data Visualization</a></li>
                            <li><a routerLink="/services-details">Data Science</a></li>
                            <li><a routerLink="/services-details">Content Marketing</a></li>
                            <li><a routerLink="/services-details">SEO & Internet</a></li>
                        </ul>
                    </div>-->

                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:+564325677896">+18088303232</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:hello@ribo.com">hello@b2befax.com</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-planet-earth"></i>
                                <div class="content">
                                    <h3>Location:</h3>
                                    <span>Mongkok, Kowloon, Hong Kong</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!--<div class="services-widget-two">
                        <h2>Brochures</h2>

                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a routerLink="/services-details">PDF Download</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>02:</h3>
                                    <span><a routerLink="/services-details">Services Details.txt</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="services-details-area">
    <div class="container">
        <h3 class="row inner-title text-center">Recent Faxes Sent</h3>
    </div>
</div>
<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/6.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="assets/img/brand/7.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="assets/img/brand/8.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>