import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    // Set the title
    this.titleService.setTitle('Privacy Policy - Recieve and Send Fax using email or Mobile Devices');

    // Set meta tags
    this.metaService.updateTag({ name: 'description', content: 'Read our privacy policy to understand how we handle collecting, using, and protecting your personal information while using our convenient email or mobile device faxing services. We prioritize the privacy and security of your data.' });
    this.metaService.updateTag({ name: 'keywords', content: 'privacy policy, receive and send fax, email faxing, mobile device faxing, personal information, data privacy, data security' });

  }

}
