<app-navbar-style-two></app-navbar-style-two>
<app-seo-agency></app-seo-agency>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Pricing Plan</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pricing Plan</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-50 pb-100">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-after">Pricing Plan</span>
            <h2 class="h2-color">Price & Plans <b>Packages</b></h2>
        </div>

        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-banner pricing-icon color-1"></i>
                            <h3 class="color-1">STANDARD</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 24.99</h2>
                                <span class="color-1">Launch Package</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> 1 DID Free</li>
                                <li><i class='bx bx-check'></i> 500 Pages</li>
                                <li><i class='bx bx-check'></i> Free Upgrade</li>
                                <li><del>OnBoarding/Training</del></li>
                                <li><del>Priority Support</del></li>
                            </ul>
                            <a href="https://portal.b2befax.com/authentication-login" target="_blank" class="purchase-btn button-bg1">Register Now</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                            <h3 class="color-2">BUSINESS</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 59.99</h2>
                                <span class="color-2">Growth Package</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i>1 DID Free</li>
                                <li><i class='bx bx-check'></i>2000 Pages</li>
                                <li><i class='bx bx-check'></i>Free Upgrade</li>
                                <li><i class='bx bx-check'></i>OnBoarding/Training</li>
                                <li><del>Priority Support</del></li>
                            </ul>
                            <a href="https://portal.b2befax.com/authentication-login" target="_blank" class="purchase-btn button-bg1">Register Now</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-processing pricing-icon color-3"></i>
                            <h3 class="color-3">ENTERPRISE</h3>
                            <div class="price-rate">
                                <h2 class="color-3">$ 129.99</h2>
                                <span class="color-3">Scale Package</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> 5 DID Free</li>
                                <li><i class='bx bx-check'></i> 5000 Pages</li>
                                <li><i class='bx bx-check'></i> Free Upgrade</li>
                                <li><i class='bx bx-check'></i> OnBoarding/Training</li>
                                <li><i class='bx bx-check'></i> Priority Support</li>
                            </ul>
                            <a href="https://portal.b2befax.com/authentication-login" target="_blank" class="purchase-btn button-bg1">Register Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/6.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="assets/img/brand/7.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="assets/img/brand/8.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>