<app-navbar-style-two></app-navbar-style-two>
<app-seo-agency></app-seo-agency>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">About Us</span>
                        <h2>Revolutionizing B2B Fax Communication</h2>
                    </div>
                    <h3>We have 16 years. Our strategy includes consistently evolving, to ensure we’re producing value to your business.</h3>
                    <p>We are developers specializing in providing support and solutions to businesses. Our expertise lies in cutting-edge technology, particularly in the realms of fax, billing, and infrastructure</p>
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>High standards of professionalism, integrity. Establishment of close working relationships.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>A different way of thinking, both inside and outside the box. We do not have to be the best.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>We do not have to be the best, we do have to give our best Ability to learn. Multicultural competence.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="about-video">
                        <a href="https://www.youtube.com/watch?v=pvbUDDOfRlM" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>16 Years</h3>
                    <p>GLORIOUS YEARS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>SATISFACTION RATE</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>80+</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>15+</h3>
                    <p>SENIOR MEMBERS</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>Our Journey’s Path</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Our Mission</h3>
                    <p>Our mission is to empower businesses with innovative <a href="https://b2befax.com/"> fax broadcasting </a> solutions, facilitating seamless communication, enhancing efficiency, and ensuring reliable, customer-focused services.</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Effortless Communication</li>
                        <li><i class='bx bx-check'></i> Reliability at Scale</li>
                        <li><i class='bx bx-check'></i> Enhanced Efficiency</li>
                        <li><i class='bx bx-check'></i> Customer-Centric Approach</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Who We Are</h3>
                    <p>We are an 80+ member team, primarily composed of developers, driven by a clear vision of pioneering high-end technology development and revolutionizing infrastructure management.</p>
                    <ul>
                        <li><i class='bx bx-check'></i> We Have You Covered</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                        <li><i class='bx bx-check'></i> We Turn Heads</li>
                        <li><i class='bx bx-check'></i> Stay Real Always</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Our History</h3>
                    <p>With a journey spanning over a decade, we've evolved from a small startup to a leading technology solutions provider. Our commitment to innovation and excellence has driven our growth</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                        <li><i class='bx bx-check'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Innovative Technology<span>1</span></h3>
                            <p>We leverage state-of-the-art technology to provide businesses with fax solutions that are not only reliable but also adaptive to the changing digital landscape.</p>
                        </li>
                        <li class="text-end">
                            <h3>Security & Compliance<span>2</span></h3>
                            <p>Data security is paramount. B2B Fax employs robust encryption and compliance measures to ensure your sensitive information remains protected</p>
                        </li>
                        <li class="text-end">
                            <h3>Efficiency<span>3</span></h3>
                            <p>Our fax solutions are designed to save you time and resources. Send and receive faxes with ease, automate processes, and reduce manual effort</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Scalability & Support</h3>
                            <p>Whether you are a small business or an enterprise, our solutions are scalable to fit business needs and increase sales, and we will assign a dedicated support to work with you 24X7</p>
                        </li>
                        <li>
                            <h3><span>5</span>Customer-Centric Approach</h3>
                            <p>We take pride in our customer-centric approach. Our dedicated support team is here to assist you every step of the way, ensuring a smooth and seamless experience</p>
                        </li>
                        <li>
                            <h3><span>6</span>Affordability</h3>
                            <p>We believe that advanced fax technology should be accessible to all businesses, regardless of size. Our pricing models are transparent and competitive</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="history-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our History</span>
            <h2>The Facts About Our Company</h2>
        </div>

        <div class="history-list">
            <ul>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2007</h3>
                        <span>February 20th</span>
                    </div>
                </li>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2016</h3>
                        <span>January 14th</span>
                    </div>
                </li>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2019</h3>
                        <span>March 25th</span>
                    </div>
                </li>
                <li class="active">
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2023</h3>
                        <span>June 10th</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2025</h3>
                        <span>February 01th</span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Great Success of the Year</h2>
                    <p>Our mission is clear and resolute: to empower businesses with advanced <a href="https://b2befax.com/">fax broadcasting</a> technology that simplifies document exchange, enhances data security, and streamlines communication processes. We envision a future where fax communication seamlessly integrates into your existing workflow, offering a secure and compliant platform for all your B2B interactions.</p>
                    <p>B2B EFax was founded in 2007 as a result of the realisation that fax communication is still an important part of corporate operations. In an age of digitization, we saw an opportunity to bridge the gap between classic fax and current technology by providing cutting-edge B2B solutions.</p>
                    <a routerLink="/about" class="default-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/history-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Data Scientist to Grow Your Business</h2> 
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/team-img7.jpg" alt="Images">
                <div class="content">
                    <h3>Cristiono Kopper</h3>
                    <span>Web Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated">
                <img src="assets/img/team/team-img8.jpg" alt="Images">
                <div class="content">
                    <h3>Jermin Jekson</h3>
                    <span>Marketing Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card">
                <img src="assets/img/team/team-img9.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img10.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/team-img11.jpg" alt="Images">
                <div class="content">
                    <h3>Hobler Jeain</h3>
                    <span>General Manager</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img12.jpg" alt="Images">
                <div class="content">
                    <h3>Julfiker Jeain</h3>
                    <span>CEO At Ostino</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<div class="testimonial-area testimonial-area-mb ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>Words From Customers</h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                            <div class="content-title">
                                <h3>David McLean</h3>
                                <span>CEO & Manager</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Jermin Jekson</h3>
                                <span>Marketing Manager</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>Julfiker Jeain</h3>
                                <span>CEO At Ostino</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<div class="services-details-area">
    <div class="container">
        <h3 class="row inner-title text-center">Recent Faxes Sent</h3>
    </div>
</div>
<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/6.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="assets/img/brand/7.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="assets/img/brand/8.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>