import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styles: [`
    .plan-table {
      border-collapse: collapse;
      width: 100%;
    }

    .plan-table th, .plan-table td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    .plan-table th {
      background-color: #f2f2f2;
    }
  `]
  
})
export class TermsConditionsComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    // Set the title
    this.titleService.setTitle('Terms & Conditions - Send Fax Securely Online By B2B E-Faxing');

    // Set meta tags
    this.metaService.updateTag({ name: 'description', content: 'Read and understand the terms and conditions for sending faxes securely online by B2B E-Faxing. Our terms define the guidelines, expectations, and responsibilities when using our secure online faxing services.' });
    this.metaService.updateTag({ name: 'keywords', content: 'terms and conditions, send fax securely online, secure faxing services, guidelines, responsibilities' });

  }

}
