
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seo-agency',
  templateUrl: './seo-agency.component.html',
  styleUrls: ['./seo-agency.component.scss']
})
export class SeoAgencyComponent implements OnInit {
  showSeoArea: boolean = false;

  closeSeoArea(): void {
    this.showSeoArea = false;
  }

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.showSeoArea = true;
    }, 3000); 
  }

}

