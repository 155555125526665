<app-navbar-style-two></app-navbar-style-two>
<app-seo-agency></app-seo-agency>


<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Sign Up</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Sign Up</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>


<div class="sign-in-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Sign Up</span>
            <h2 class="h2-color">Create an Account!</h2>
        </div>

        <div class="row align-items-center pt-45">
            <div class="col-lg-5 col-md-12">
                <div class="user-all-img">
                    <img src="assets/img/faq-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="user-all-form">
                    <div class="contact-form">
                        <form [formGroup]="form">
                            <div class="form-group">
                                <i class='bx bx-printer'></i>
                                <!-- <label for="selectTemplate">Select Fax </label> -->
                                <select class="form-select"  id="selectTemplate" formControlName="faxtype">
                                   <!--  <option selected>Select Fax Type</option> -->
                                    <option value="1">Bulk Fax</option>
                                    <option value="2">Inbound/Outbound Fax</option>
                                </select>
                                <span *ngIf="f.faxtype.invalid && (f.faxtype.touched || submitted || f.faxtype.dirty)"
                                    class="text-danger">
                                    <span class="text-danger downline" *ngIf=" f.faxtype.errors.required">Fax Type is
                                        required</span>
                                </span>
                            </div>

                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="text" formControlName="first_name" id="first_name" class="form-control"
                                    data-error="Please enter your First Name" placeholder="First Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                                <span
                                    *ngIf="f.first_name.invalid && (f.first_name.touched || submitted || f.first_name.dirty)"
                                    class="text-danger">
                                    <span class="text-danger downline" *ngIf=" f.first_name.errors.required">First Name
                                        is required</span>
                                </span>
                            </div>

                            <div class="form-group">
                                <i class='bx bx-user'></i>
                                <input type="text" formControlName="last_name" id="last_name" class="form-control"
                                    data-error="Please enter your Last Name" placeholder="Last Name"
                                    [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                                <span
                                    *ngIf="f.last_name.invalid && (f.last_name.touched || submitted || f.last_name.dirty)"
                                    class="text-danger">
                                    <span class="text-danger downline" *ngIf=" f.last_name.errors.required">Last name is
                                        required</span>
                                </span>
                            </div>

                            <div class="form-group">
                                <i class='bx bx-mail-send'></i>
                                <input type="email" formControlName="email" id="email" class="form-control"
                                    data-error="Please enter email" placeholder="Email"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                <span *ngIf="f.email.invalid && (f.email.touched || submitted || f.email.dirty)"
                                    class="text-danger">
                                    <span class="text-danger downline" *ngIf=" f.email.errors.required">Email is
                                        required</span>
                                </span>
                            </div>

                            <div class="form-group">
                                <i class='flaticon-telephone'></i>
                                <input type="text" formControlName="contact" id="contact" class="form-control"
                                    data-error="Please enter contact" placeholder="Contact"
                                    [ngClass]="{ 'is-invalid': submitted && f.contact.errors }">
                                <span *ngIf="f.contact.invalid && (f.contact.touched || submitted || f.contact.dirty)"
                                    class="text-danger">
                                    <span class="text-danger downline" *ngIf=" f.contact.errors.required">Contact is
                                        required</span>
                                </span>
                            </div>

                            <!-- <div class="form-group">
                                <i class='bx bx-lock-alt'></i>
                                <input class="form-control" type="password" name="password" placeholder="Password">
                            </div> -->

                            <div class="text-center">
                                <button type="submit" (click)="onSubmit()" class="default-btn">Sign Up <i
                                        class='bx bx-plus'></i></button>
                            </div>

                            <p class="account-desc">Already have an account? <a href="http://auth.b2befax.com/"
                                    target="_blank">Sign In</a></p>
                        </form>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<div class="strip">
    <div class="strip-width">
      <div class="row align-items-center">
        <div class="col-lg-12 col-md-12">
            <div class="strip-form">
                <!-- <h2>"5 FREE faxes and free Demo"</h2> -->
                <h2>5 FREE FAXES & FREE DEMO</h2>
              </div>
        </div>
        <!-- <div class="col-lg-4 col-md-12">
          <div class="col-lg-12 col-md-12 btn-strip">
            <button type="submit" class="default-btns border-radius" [routerLink]="['/sign-up']">Sign Up <i class='bx bx-plus'></i></button>
         </div>
        </div> -->
    </div>
  </div>
</div>

<div class="container" *ngIf="isposted==1">

    <div class="row">
        <div class="col-md-4">
            <div class="popup-card1">
                <div class="popup-topray">
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="popup-circle mt-5 d-flex justify-content-center align-items-center">
                        <span class="material-symbols-outlined popicon">
                            check
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-4">
                    <h4 class="text-center">SUCCESS!</h4>
                </div>
                <div class="d-flex justify-content-center align-items-center popup-insidediv">
                    <p class="text-center">{{resdata}}</p>
                </div>
                <div class="d-flex justify-content-center">
                    <button type="button" class="popupbtn" (click)="Close(1)">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="isposted==2">
    <div class="row">
        <div class="col-md-4">
            <div class="popup-card2">
                <div class="popup-topray">
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="popup-circle mt-5 popicon2 d-flex justify-content-center align-items-center">
                        <span class="material-symbols-outlined">
                            close
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-4">
                    <h4 class="text-center">FAILURE!</h4>
                </div>
                <div class="d-flex justify-content-center align-items-center popup-insidediv">
                    <p class="text-center">{{resdata}}</p>
                </div>
                <div class="d-flex justify-content-center">
                    <button type="button" class="popupbtn2" (click)="Close(2)">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
</div>


<app-footer-style-two></app-footer-style-two>