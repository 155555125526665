<app-navbar-style-two></app-navbar-style-two>
<app-seo-agency></app-seo-agency>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="contact-area pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Contact</span>
            <h2>Get in Touch</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-planet-earth"></i>
                    <h3>Office Location</h3>
                    <p>Room 1614, 16/F, Wealth Commercial</p>
                    <p> Centre, 48 Kwong Wa Street,</p>
                    <p>Mongkok, Kowloon, Hong Kong</p>
                    <a target="_blank" href="#" class="contact-card-btn">Direction <i
                            class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3>Contact</h3>
                    <p>hello@b2befax.com</p>
                    <p>Phone: +1 (808)830-3232</p>
                    <a routerLink="/" class="contact-card-btn">Know More <i class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-clock"></i>
                    <h3>Hours of Operation</h3>
                    <p>Monday - Friday: 09:00 - 20:00</p>
                    <p>Sunday & Saturday: 10:30 - 22:00</p>
                    <a href="mailto:hello@b2befax.com" class="contact-card-btn">Support <i
                            class='bx bx-plus plus-btn'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-section pt-100 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-img">
                    <img src="assets/img/contact-img.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="section-title">
                            <span class="sp-before sp-after">Contact</span>
                            <h2>Contact With Us</h2>
                        </div>

                        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" formControlName="first_name" class="form-control" placeholder="Your First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                                        <span *ngIf="f.first_name.invalid && (f.first_name.touched || submitted || f.first_name.dirty)" class="text-danger">
                                            <span class="text-danger downline" *ngIf=" f.first_name.errors.required">First Name is required</span>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="text" formControlName="last_name" class="form-control" placeholder="Your Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                                        <span *ngIf="f.last_name.invalid && (f.last_name.touched || submitted || f.last_name.dirty)" class="text-danger">
                                            <span class="text-danger downline" *ngIf=" f.last_name.errors.required">Last Name is required</span>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-user'></i>
                                        <input type="email" formControlName="email" class="form-control" placeholder="Your Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <span *ngIf="f.email.invalid && (f.email.touched || submitted || f.email.dirty)" class="text-danger">
                                            <span class="text-danger downline" *ngIf=" f.email.errors.required">Email is required</span>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class='bx bx-phone'></i>
                                        <input type="text" formControlName="phone_number" class="form-control" placeholder="Your Phone" [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }">
                                        <span *ngIf="f.phone_number.invalid && (f.phone_number.touched || submitted || f.phone_number.dirty)" class="text-danger">
                                            <span class="text-danger downline" *ngIf=" f.phone_number.errors.required">Contact is required</span>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class='bx bx-envelope'></i>
                                        <textarea formControlName="message" class="form-control" cols="30" rows="4" placeholder="Your Message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                        <span *ngIf="f.message.invalid && (f.message.touched || submitted || f.message.dirty)" class="text-danger">
                                            <span class="text-danger downline" *ngIf=" f.message.errors.required">Message is required</span>
                                        </span>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn border-radius">Send Message <i class='bx bx-plus'></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container" *ngIf="isposted==1">

    <div class="row">
        <div class="col-md-4">
            <div class="popup-card1">
                <div class="popup-topray">
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="popup-circle mt-5 d-flex justify-content-center align-items-center">
                        <span class="material-symbols-outlined popicon">
                            check
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-4">
                    <h4 class="text-center">Thank You!</h4>
                </div>
                <div class="d-flex justify-content-center align-items-center popup-insidediv">
                    <p class="text-center">We appreciate you contacting us. Our support team will get back in touch with you soon!Have a great day!
                    </p>
                </div>
                <div class="d-flex justify-content-center">
                    <button type="button" class="popupbtn" (click)="Close(1)">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.071382552201!2d77.38930337425693!3d28.597635285666822!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff2d94f9c5d%3A0x93da4264c7651e99!2sGventure%20Technology%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1693305361670!5m2!1sen!2sin"
            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>