<app-navbar-style-two></app-navbar-style-two>
<app-seo-agency></app-seo-agency>

<div class="banner-area-two">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-content-two">
                        <span>Best for You</span>
                        <h1>We’re <b>Fax Broadcasting</b> Startup</h1>
                        <p>Your Message, Our Delivery, Fax Broadcasting Excellence</p>
                        <div class="banner-content-btn">
                            <!-- /contact -->
                            <a class="learn-btn" data-toggle="modal" data-target="#contactModal">Contact us<i
                                    class='bx bx-plus'></i></a>
                            <a href="https://www.youtube.com/watch?v=IQSskvplyv8" class="play-on-btn popup-btn">Play
                                Video <i class='flaticon-forward'></i></a>
                        </div>
                    </div>
                </div>
                <!-- Modal -->
                <div class="modal fade" id="contactModal" tabindex="-1" role="dialog"
                    aria-labelledby="contactModalLabel" aria-hidden="true" #contactModal>
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="contactModalLabel">Contact Us</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="">
                                    <div class="contact-form">
                                        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                                            <div class="row">
                                                <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="text" formControlName="first_name" class="form-control" placeholder="Your First Name" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                                                        <span *ngIf="f.first_name.invalid && (f.first_name.touched || submitted || f.first_name.dirty)" class="text-danger">
                                                            <span class="text-danger downline" *ngIf=" f.first_name.errors.required">First Name is required</span>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <i class='bx bx-file'></i>
                                                        <input type="text" formControlName="last_name"
                                                            class="form-control" placeholder="Your Last Name" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
                                                        <span *ngIf="f.last_name.invalid && (f.last_name.touched || submitted || f.last_name.dirty)" class="text-danger">
                                                            <span class="text-danger downline" *ngIf=" f.last_name.errors.required">Last Name is required</span>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <i class='bx bx-user'></i>
                                                        <input type="email" formControlName="email" class="form-control" placeholder=" Your E-mail" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                        <span *ngIf="f.email.invalid && (f.email.touched || submitted || f.email.dirty)" class="text-danger">
                                                            <span class="text-danger downline" *ngIf=" f.email.errors.required">Email is required</span>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <i class='bx bx-phone'></i>
                                                        <input type="text" formControlName="phone_number"
                                                            class="form-control" placeholder="Your Phone" [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }">
                                                        <span *ngIf="f.phone_number.invalid && (f.phone_number.touched || submitted || f.phone_number.dirty)" class="text-danger">
                                                            <span class="text-danger downline" *ngIf=" f.phone_number.errors.required">Contact is required</span>
                                                        </span>
                                                    </div>
                                                </div>                                                

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <i class='bx bx-envelope'></i>
                                                        <textarea formControlName="message" class="form-control" cols="30" rows="4" placeholder="Your Message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                                        <span *ngIf="f.message.invalid && (f.message.touched || submitted || f.message.dirty)" class="text-danger">
                                                            <span class="text-danger downline" *ngIf=" f.message.errors.required">Message is required</span>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <button type="submit" class="default-btn border-radius">Send Message
                                                        <i class='bx bx-plus'></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container" *ngIf="isposted==1">

                    <div class="row">
                        <div class="col-md-4">
                            <div class="popup-card1">
                                <div class="popup-topray">
                                </div>
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="popup-circle mt-5 d-flex justify-content-center align-items-center">
                                        <span class="material-symbols-outlined popicon">
                                            check
                                        </span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center align-items-center mt-4">
                                    <h4 class="text-center">Thank You!</h4>
                                </div>
                                <div class="d-flex justify-content-center align-items-center popup-insidediv">
                                    <p class="text-center">We appreciate you contacting us. Our support team will get back in touch with you soon!Have a great day!
                                    </p>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <button type="button" class="popupbtn" (click)="Close()">CLOSE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="banner-img-2">
                        <img src="assets/img/home-two/home-two-img.png" alt="Fax Broadcasting Solution">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-bottom-shape">
        <div class="shape-one wow fadeInUp" data-wow-delay=".3s"><img src="assets/img/home-two/home-two-shape1.png"
                alt="Images"></div>
        <div class="shape-two wow fadeInUp" data-wow-delay=".5s"><img src="assets/img/home-two/home-two-shape2.png"
                alt="Images"></div>
        <div class="shape-three wow fadeInUp" data-wow-delay=".7s"><img src="assets/img/home-two/home-two-shape3.png"
                alt="Images"></div>
        <div class="banner-dots1"><img src="assets/img/home-two/home-two-dots.png" alt="Images"></div>
        <div class="banner-dots2"><img src="assets/img/home-two/home-two-dots2.png" alt="Images"></div>
    </div>
</div>

<div class="service-area-two">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>Our Implementation Mission is to Serve You Best</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3><a routerLink="/services-details">Simple configuration with intuitive controls</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon7.png"
                                alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Easily send a fax to multiple recipients from your computer, tablet, or cell phone. With just a
                        few clicks and a subscription to b2beFax, you can effortlessly set up a bulk fax campaign.</p>
                    <a target="_blank" routerLink="/services-details" class="learn-btn">Demo<i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <h3><a routerLink="/services-details">Customizable fields for lists and groups</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon8.png"
                                alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Using b2beFax, sending faxes to various groups is as straightforward as sending an email. Instead
                        of having to edit each document separately, organize your contacts into lists and deliver
                        tailored messages that encourage ongoing engagement.</p>
                    <a  target="_blank" routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="services-item">
                    <h3><a routerLink="/services-details">Secure fax blast at no additional cost</a></h3>
                    <div class="services-item-img">
                        <a routerLink="/services-details"><img src="assets/img/service/service-icon9.png"
                                alt="Images"></a>
                        <div class="img-circle"></div>
                    </div>
                    <p>Deliver press releases and marketing campaigns with peace of mind. Our bulk faxing solution
                        employs top-tier military-grade encryption to protect your contact list and online data from
                        potential cyber threats.</p>
                    <a  target="_blank" routerLink="/services-details" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="service-two-shape">
        <div class="shape-in1"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-in2"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-in3"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-in4"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-in5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="about-area-two pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img-2">
                        <img src="assets/img/about/about-img2.png" alt="Fax blast services">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content-two">
                        <div class="section-title">
                            <span class="sp-before sp-after">About Us</span>
                            <h2>Grow Business With Fax Broadcasting Solution</h2>
                        </div>
                        <h3>With 7 years of experience, we are now focused on adopting the best technology and evolving strategies to deliver exceptional results for businesses.</h3>
                        <p>Our commitment is to consistently provide outstanding results for businesses. Leveraging FreeSWITCH, we possess a robust capability for rapidly transmitting a large volume of faxes. Our network empowers you to achieve an output of 20,000 faxes per minute on your dedicated instance.</p>
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>01</span>
                                    <p>Efficient</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>02</span>
                                    <p>Seamless</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>03</span>
                                    <p>Dependable</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>04</span>
                                    <p>Secure</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>05</span>
                                    <p>Customizable</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 pr-0">
                                <div class="about-list">
                                    <i class='bx bx-check'></i>
                                    <span>06</span>
                                    <p>Affordable</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>2 Million</h3>
                    <p>Faxes per month</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>80%</h3>
                    <p>Success rate</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>10+</h3>
                    <p>TEAM MEMBERS</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>100%</h3>
                    <p>Uptime & dedicated Support</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top"><img src="assets/img/counter/counter-shape.png" alt="Images"></div>
</div>

<div class="service-another pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>We Offer Professional Solutions For Your Business</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-another-card service-card-height">
                    <a routerLink="/services-details"><i
                            class="flaticon-research service-icon service-icon-bg1"></i></a>
                    <h3><a routerLink="/services-details">Medical-Healthcare</a></h3>
                    <p>Effortlessly streamline patient information, appointment reminders, and critical updates to
                        healthcare providers with our secure, efficient <a href="https://b2befax.com/">fax
                            broadcasting</a> solutions tailored for the medical industry.</p>
                    <a target="_blank" routerLink="/services-details/medical-healthcare" class="learn-btn">Learn more <i
                            class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card service-card-height">
                    <a routerLink="/services-details"><i
                            class="flaticon-headphones service-icon service-icon-bg"></i></a>
                    <h3><a routerLink="/services-details">Insurance</a></h3>
                    <p>Enhance policyholder communication by securely sending policy updates, claims information, and
                        notifications to clients and agents with our efficient <a href="https://b2befax.com/">fax
                            broadcasting</a> services.
                    </p>
                    <a target="_blank" routerLink="/services-details/insurance" class="learn-btn">Learn more <i
                            class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card service-card-height">
                    <a routerLink="/services-details"><i class="flaticon-caution service-icon service-icon-bg2"></i></a>
                    <h3><a routerLink="/services-details">Real-Estate</a></h3>
                    <p>Seamlessly distribute property listings, market updates, and important announcements to clients
                        and agents with our reliable, high-speed <a href="https://b2befax.com/">fax broadcasting</a>
                        solutions designed for the real estate industry.</p>
                    <a target="_blank" routerLink="/services-details/real-estate" class="learn-btn">Learn more <i
                            class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card service-card-height">
                    <a routerLink="/services-details"><i
                            class="flaticon-big-data service-icon service-icon-bg3"></i></a>
                    <h3><a routerLink="/services-details">Legal</a></h3>
                    <p>Efficiently transmit legal documents, case updates, and important notices to clients and legal
                        professionals with our secure, reliable <a href="https://b2befax.com/">fax broadcasting</a>
                        services tailored for the legal industry.

                    </p>
                    <a  target="_blank" routerLink="/services-details/legal" class="learn-btn">Learn more <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card service-card-height">
                    <a routerLink="/services-details"><i class="flaticon-money service-icon service-icon-bg4"></i></a>
                    <h3><a routerLink="/services-details">Finance</a></h3>
                    <p>Quickly and securely send financial reports, investment updates, and client notifications with
                        our efficient <a href="https://b2befax.com/">fax broadcasting</a> solutions designed for the
                        finance industry.

                    </p>
                    <a  target="_blank" routerLink="/services-details/finance" class="learn-btn">Learn more <i
                            class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-another-card service-card-height">
                    <a routerLink="/services-details"><i class="flaticon-share service-icon service-icon-bg5"></i></a>
                    <h3><a routerLink="/services-details">Other Industry</a></h3>
                    <p>Streamline communication by securely and efficiently delivering essential information, updates,
                        and announcements tailored to the unique needs of any industry with our versatile <a
                            href="https://b2befax.com/">fax broadcasting</a> services.
                    </p>
                    <a  target="_blank" routerLink="/services-details/other-industry" class="learn-btn">Learn more <i
                            class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="service-another-shape">
        <div class="shape-1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape-6"><img src="assets/img/shape/shape12.png" alt="Images"></div>
    </div>
</div>

<div class="work-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color2">Simple & Clean Work Process</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="work-item-list">
                    <ul>
                        <li class="text-end">
                            <h3>Register Account<span>1</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Verify Account<span>2</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li class="text-end">
                            <h3>Login Account<span>3</span></h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-img-2">
                    <img src="assets/img/work-img.png" alt=" Online Bulk Fax Service">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="work-item-list-2">
                    <ul>
                        <li>
                            <h3><span>4</span>Upload Contacts</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>5</span>Upload Fax</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                        <li>
                            <h3><span>6</span>Send Faxes</h3>
                            <p>Lorem ipsum dolor sit amet, aut odiut podit afugitsed quia consequuntur </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="strip">
        <div class="strip-width">
          <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="strip-form">
                    <!-- <h2>"5 FREE faxes and free Demo"</h2> -->
                    <h2>5 FREE FAXES & FREE DEMO</h2>
                  </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="col-lg-12 col-md-12 btn-strip">
                <button type="submit" class="default-btns border-radius" [routerLink]="['/sign-up']">Sign Up <i class='bx bx-plus'></i></button>
             </div>
            </div>
        </div>
    </div>
</div>

<div class="mission-area pt-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="mission-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Mission</span>
                        <h2 class="h2-color2">We Strive to Exceed Your Expectations</h2>
                    </div>
                    <p>Unlock the potential of our outstanding services. We consistently exceed your expectations,
                        delivering results beyond compare</p>
                    <div class="row">
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>24X7 Support Team</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Priority Support</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Easy OnBoarding</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>04</span>
                                <p>Technology Experience</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>05</span>
                                <p>Business Growth</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="mission-list">
                                <i class='bx bx-check'></i>
                                <span>06</span>
                                <p>Business Vision</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="mission-img">
                    <img src="assets/img/mission-img.png" alt="Fax Broadcasting Software">
                </div>
            </div>
        </div>
    </div>

    <div class="mission-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area">
    <div class="container">
        <h3 class="row inner-title text-center">Recent Faxes Sent</h3>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/6.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="assets/img/brand/7.png" alt="Images">
                </div>
                <div class="brand-logo-item">
                    <img src="assets/img/brand/8.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="data-table-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="data-table-content">
                    <h2>We Like to Start Your Project With Us</h2>
                    <a routerLink="/contact" class="default-btn">Get started <i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="data-table-img">
                    <img src="assets/img/data-table-img.png" alt="Images">                    
                </div>
            </div>

        </div>
    </div>
</div>

<div class="faq-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">FAQ</span>
            <h2 class="h2-color2">Frequently Asked Questions</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> What
                                is Fax Broadcasting?</a>
                            <div class="accordion-content">
                                <p><a href="https://b2befax.com/">Fax broadcasting</a> is a marketing technique that
                                    involves sending the same document or message to multiple fax numbers
                                    simultaneously.
                                </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> How
                                does Fax Broadcasting work? </a>
                            <div class="accordion-content">
                                <p><a href="https://b2befax.com/">Fax broadcasting</a> works by using specialized
                                    software or services to transmit faxes to a list of recipients all at once, saving
                                    time and effort compared to sending individual faxes.
                                </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i>What
                                are the benefits of Fax Broadcasting for businesses? </a>
                            <div class="accordion-content">
                                <p> <a href="https://b2befax.com/">Fax broadcasting</a> offers benefits such as reaching
                                    a targeted audience, delivering documents securely, and providing a cost-effective
                                    marketing solution.
                                </p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)"><i
                                    class='bx bx-chevron-down'></i> Is Fax Broadcasting still effective in the digital
                                age?
                            </a>
                            <div class="accordion-content show">
                                <p>Yes, <a href="https://b2befax.com/">Fax broadcasting</a> remains effective in the
                                    digital age due to its direct and reliable communication method, especially for
                                    industries where fax communication is still prevalent.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> What
                                equipment is needed to send a fax broadcast?
                            </a>
                            <div class="accordion-content">
                                <p>To send a fax broadcast, businesses typically need a fax machine or an online fax
                                    service provider that offers Fax Broadcasting capabilities.</p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> How
                                can businesses measure the success of a fax broadcast campaign? </a>
                            <div class="accordion-content">
                                <p>Businesses can measure the success of a fax broadcast campaign by tracking metrics
                                    such as delivery rates, response rates, and conversion rates.</p>
                            </div>
                        </li>

                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-chevron-down'></i> How
                                to do a Fax Blast? </a>
                            <div class="accordion-content">
                                <p>To conduct a Fax Blast, businesses need to compile a list of fax numbers, create a
                                    compelling message or document, select a Fax Broadcasting service or software, and
                                    schedule the blast to send to the targeted recipients.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="centerdiv">
    <div class="card custom-card">
        <div class="card-body">
            <div class="row w-100">
                <div class="col-md-12 mt-4">
                    <p class="pstyle text-center fontsize">SUBSCRIBE TO OUR NEWSLETTER</p>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-8 d-flex justify-content-center align-items-center pattern">
                        <div class="input-group position-relative">
                            <input type="email" class="form-control custom-email-input rounded-borders"
                                placeholder="Enter your email">
                            <button class="custom-subscribe-button rounded-borders" type="button">Subscribe</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>