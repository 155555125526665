<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img" routerLink="">
                            <img src="assets/img/logo/B2BFAX.svg" alt="B2B-Efax">
                        </div>
                        <p>Discover our Implementation Mission: Simplicity, Efficiency, and Security. Send bulk faxes effortlessly, customize your lists, and ensure data security with b2beFax.</p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/b2befax/" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="https://x.com/B2bEfax" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="https://www.instagram.com/b2befax/" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                                <li><a href="https://www.pinterest.com/b2befax/" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                                <li><a href="https://www.youtube.com/@B2beFax" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Resources</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/about">Our Team</a></li>
                            <li><a routerLink="/services">Our Services</a></li>
                            <li><a routerLink="/coming-soon">Reseller</a></li>
                            <li><a routerLink="/coming-soon">Affiliate</a></li>
                            
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Company</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/pricing">Pricing</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two">
                            <li><i class='flaticon-telephone'></i><a href="tel:+18088303232">+1 (808)830-3232</a></li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:hello@b2befax.com">hello@b2befax.com</a></li>
                            <li><i class='flaticon-place'></i>30 N Gould St Ste R Sheridan, Wyoming 82801, USA</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>© Designed & Developed by <a href="https://b2befax.com/">B2BeFax</a></p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul>
                            <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>