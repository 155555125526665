<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Team</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Team</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="team-area-two pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color2">Meet Our Data Scientist to Grow Your Business</h2> 
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="team-card team-card-mt active">
                    <img src="assets/img/team/team-img7.jpg" alt="Images">
                    <div class="content">
                        <h3>Cristiono Kopper</h3>
                        <span>Web Developer</span>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>
           
            <div class="col-lg-4 col-md-6">
                <div class="team-card team-card-mt active">
                    <img src="assets/img/team/team-img8.jpg" alt="Images">
                    <div class="content">
                        <h3>Jermin Jekson</h3>
                        <span>Marketing Manager</span>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card team-card-mt active">
                    <img src="assets/img/team/team-img9.jpg" alt="Images">
                    <div class="content">
                        <h3>Hobler Jeain</h3>
                        <span>General Manager</span>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card team-card-mt active">
                    <img src="assets/img/team/team-img10.jpg" alt="Images">
                    <div class="content">
                        <h3>Julfiker Jeain</h3>
                        <span>CEO At Ostino</span>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card team-card-mt active">
                    <img src="assets/img/team/team-img11.jpg" alt="Images">
                    <div class="content">
                        <h3>Hobler Jeain</h3>
                        <span>General Manager</span>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card team-card-mt active">
                    <img src="assets/img/team/team-img12.jpg" alt="Images">
                    <div class="content">
                        <h3>Julfiker Jeain</h3>
                        <span>CEO At Ostino</span>
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>