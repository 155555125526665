<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo/logo-b2b11.png" alt="Logo"></a>
    </div>

    <div class="main-nav nav-bar-two">
        <div class="container-fluid">
            <nav class="container-max-2 navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/bg-white.svg" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a routerLink="" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home
                                <!-- <i class='bx bx-plus'></i> -->
                            </a>
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                        <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Pricing</a></li>
                        
                        <!-- <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog</a></li> -->

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                       <!--  <li class="nav-item"><a href="http://auth.b2befax.com/" class="nav-link" target="_blank" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Login</a></li>

                        <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li> -->
                    </ul>

                   
                    <div class="side-nav d-flex align-items-center">
                        <ul class="navbar-nav">
                        
                            <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>
    
                            <li class="nav-item"><a href="javascript:void()" class="nav-link">|</a></li> 
    
                            <li class="nav-item"><a href="http://auth.b2befax.com/" class="nav-link" target="_blank" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Login</a></li>
                        </ul>
                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        <!-- <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div> -->
                        <!-- <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn">Contact Us <i class='bx bx-plus'></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn">Contact Us <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>

            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>

            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
