import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  public submitted:boolean=false;
  public isposted:number=0;

  get f() {
    return this.contactForm.controls;
  }

  constructor(private titleService: Title, private metaService: Meta,  private fb: FormBuilder, protected http: HttpClient) { 

    this.contactForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      /* msg_subject: ['', Validators.required], */
      message: ['', Validators.required]
    });
  

  }

  ngOnInit(): void {
    // Set the title
    this.titleService.setTitle('For Fax Contact Us- Fax Marketing By B2B E-Faxing');

    // Set meta tags
    this.metaService.updateTag({ name: 'description', content: 'Boost your B2B marketing efforts with our professional fax marketing services. Contact us today for high-quality e-fax solutions and maximize your outreach.' });
    this.metaService.updateTag({ name: 'keywords', content: 'fax marketing, B2B e-faxing, contact us, fax solutions, B2B marketing strategies' });

  }

  onSubmit(): void {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    console.log(this.contactForm.value);
    let cdata = JSON.stringify(this.contactForm.value);
    
      this.http.post<any>(environment.Portal3, { "data": cdata }, { headers: { "Content-Type": "application/json" } }).subscribe(res => {
        this.isposted=1;
        console.log(res);
      }, err => {
          console.log(err);
          this.isposted=1;
      });
  }

  Close(type:number){
    this.isposted=0;
    this.contactForm.reset();

    Object.keys(this.contactForm.controls).forEach(key => {
      this.contactForm.controls[key].setErrors(null);
    });
    

  }


}
