import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  
})
export class ServicesComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
     // Set the title
     this.titleService.setTitle('Best Internet fax services - Secure fax service online');

     // Set meta tags
     this.metaService.updateTag({ name: 'description', content: 'B2BeFax service allow you to send faxes electronically.They often provide online platforms or mobile apps for easy faxing.Send bulk faxes effortlessly, customize your lists, and ensure data security.' });
     this.metaService.updateTag({ name: 'keywords', content: 'internet fax services, secure fax service online, fax communication, confidentiality, integrity, digital faxes' });
 
  }

}
