<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Terms & Conditions</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Terms & Conditions</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="terms-conditions-area pt-50 pb-70">
    <div class="container">
        <div class="single-content">
              <h1>Terms of Use</h1>

    <p>These Terms of Use (“Terms”) describe the terms under which Gventure Communication Ltd HongKong (“B2BFAX”, “We”, “Our” “Us”) provides subscriber access to and use of Our Service(s) (“You”, “Your”, “Yourself”). Gventure Communication Ltd HongKong. is the authorized representative which operates/runs the Services. By accessing and/or using Our Service,</p>

    <ol>
        <li>You agree to be bound by these Terms and acknowledge having read the privacy policy located at <a href="#">[Privacy Policy Link]</a>.</li>
        <li>You warrant to us that you are of 18 years or above and are competent to enter into this agreement.</li>
        <li>In the event You are entering into these Terms on behalf of any entity/company or its group, You possess the requisite authority to bind such entities, company or its groups to these Terms.</li>
    </ol>

    <p>If You do not agree to these Terms, You should immediately cease using our Service(s).</p>

    <p>You and Us will be individually referred to as Party and collectively as Parties.</p>

    <h4>1. YOUR RIGHTS</h4>

    <ol>
        <li>Subject to Your compliance with these Terms and solely during the Subscription Term, You shall have the limited, non-exclusive, revocable right to access and use the Service(s) for your business communication purposes in accordance with the subscription plan specifically stated in the Website or in an Order Form.</li>
    </ol>

    <h4>2. YOUR RESPONSIBILITIES</h4>

    <ol>
        <li>Your Account:
            <p>Your access and use of the Service(s) is restricted to the specified number of individual Users as specified in the relevant Order Form executed between Us and Yourself. Each User shall be identified using unique login information such as usernames and passwords (“User Login”) and such User Login shall be used only by one individual.</p>
        </li>
        <li>Acceptable Use:
            <p>You agree not to:</p>
            <ul>
                <li>(a) license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise commercially exploit or make the Service(s) available to any third party, other than Users in furtherance of Your business communication purposes as expressly permitted by these Terms;</li>
                <li>(b) modify, adapt, or hack the Service(s) or otherwise attempt to gain or gain unauthorized access to the Service(s) or related systems or networks;</li>
                <li>(c) use the Service(s) in violation of applicable laws and regulations, including but not limited to violation of any person’s privacy rights, export control laws/regulations; </li>
				<li>(d) use the Service(s) to transmit any content that is unlawful, racist, hateful, abusive, libelous, obscene, or discriminatory; </li>
				<li>(e) use the Service(s) to knowingly , transmit, upload, link to, send or store any viruses, malware, trojan horses, time bombs, or any other similar harmful software; </li>
				<li>(f) “crawl,” “scrape,” or “spider” any page, data, or portion of or relating to the Services (through use of manual or automated means); </li>
				<li>(g) use the Services to send unsolicited commercial communications.</li>
				<!-- Add other points as needed -->
            </ul>
        </li>
        <li><p>Where You use the Service(s), You acknowledge that it is not intended for making calls to any emergency services and that We shall have no liability arising from the use of the Services to make emergency calls.</p></li>
        <li><p>You understand and agree that the Services, which are provided over the Internet, shall not be used to connect to Indian fixed line/mobile numbers, except where You have the requisite permissions or licenses under Indian law.</p></li>
        <li><p>We offer the option for You to record calls as a part of the Services. You warrant that you shall at all times comply with all applicable laws prior to recording any telephone calls. It is recommended that You always secure the consent of the other party before recording.</p></li>
        <li><p>You acknowledge that SMS capabilities on any country’s number provided via the Services are only functional as per the regulations and restrictions of that particular country. Therefore, We do not encourage the use of SMS (verification, transactional, or promotional) through a virtual number.</p></li>
        <li><p>If We inform You that a specified activity or purpose is prohibited with respect to the Service(s), You will immediately cease using the Service(s) for such prohibited activity or purpose.</p></li>
        <li><p>You represent and warrant to Us that You own or have the necessary rights to transmit the Customer Content to Us and that doing so does not violate any applicable law, proprietary or privacy rights.</p></li>
        <li><p>This section pertains to telephony services, with all calls made to the U.S. and Canada being billed in increments of sixty seconds, with a minimum charge of sixty seconds. Gventure Communication will make available to the customer, upon request, DID and toll-free telephone numbers where available. “Short Duration” calls are defined as calls lasting six seconds or less, although the duration for such calls may be subject to change or vary based on jurisdiction. If the customer intends to exchange Short Duration traffic, they must inform Gventure Communication. Additionally, Short Duration traffic is subject to an extra charge of $0.01 per call, on top of all other applicable charges. Finally, Gventure Communication reserves the right to transfer Short Duration traffic to an alternate platform, at their sole discretion.</p></li>
   
		<!-- Add other responsibilities as needed -->
    </ol>
  
       <h4>3. SERVICE(S)</h4>

    <ol>
        <li>You may request a demo of Our Service(s) or a trial of the Service(s) by creation of accounts for trial use for a limited period of time (“Trial Period”). The Trial Period shall be subject to these Terms and any additional terms that We specify. We, in our sole discretion, shall have the right to terminate the Service(s) and Your right to use the Service(s) at any time during the Trial Period and for any reason, without being liable to You.</li>
        <li>Any enhancements, new features or updates (“Updates”) to the Service(s) are also subject to these Terms and We reserve the right to deploy Updates at any time.</li>
        <li>The features and functions of the Services, including the APIs may change over time; provided, however, We will not materially decrease the overall functionality of the Services.</li>
        <li>The Service(s) may temporarily be unavailable due to scheduled downtime for upgrades and maintenance in which case We shall use commercially reasonable endeavors to notify You in advance.</li>
        <li>You may also request us to help You port the Gventure Communication Numbers subject to such terms and conditions imposed by Us.</li>
        <li>If You are in any of the following businesses You may not use our Service(s), and You agree that We may terminate Your use of the Service(s) if you are in any of the following businesses: PC support, printerfix services, airline ticket books, traditional healer and spell casting, customized computers, online toy store, instant email, SEO services, technical support and antivirus services, technical support, web designing and online dating.</li>
    </ol>
	 <h4>4. INTELLECTUAL PROPERTY RIGHTS</h4>

    <ol>
        <li>Except for the rights granted to You under clause 1, all rights, title, and interest in and to all intellectual property and/or proprietary rights, title and interest in or related to the Service(s), including patents, inventions, copyrights, trademarks, domain names, trade secrets or know-how (collectively, “Intellectual Property Rights”) shall belong to and remain exclusively with Us.</li>
        <li>You own the rights to the Customer Content that You provide to Us. We do not claim ownership over such Customer Content. We shall have a right and license to incorporate into the Services or otherwise use any suggestions, enhancement requests, recommendations or other feedback we receive from You.</li>
        <li>We reserve our rights to re-use any and all techniques and know-how gathered by Us while providing the Services to You. All rights not expressly provided to You herein are reserved.</li>
    </ol>

    <h4>5. THIRD PARTY SERVICES</h4>

    <ol>
        <li>
            <h5>5.1</h5>
            <p>You acknowledge and agree that Your use of Third-party Services will be subject to the terms and conditions and privacy policies of such third-party and that We shall not be liable for Your enablement, access, or use of such Third-party Services, including Your data processed by such third party. It is Your responsibility to ensure that each Third Party Services (list can be found at Gventure Communication App Marketplace | Integrations for Better Business) is compatible with the Services. You should contact that Third-party service provider for any issues arising in connection with use of such Third-party Service.</p>
        </li>
    </ol>

    <h4>6. GVENTURE COMMUNICATION PHONE NUMBERS</h4>

    <ol>
        <li>You may purchase and allocate Gventure Communication Numbers to your Account subject to compliance with the allocation requirements specified upon subscription to the Gventure Communication Numbers. In particular, You are responsible for compliance with any requirements related to Your residence and/or location.</li>
        <li>Your allocated Gventure Communication Number shall be activated within 15 (fifteen) working days from the date of purchase, subject to submission of all documentation requested by Us. You acknowledge and agree that we may request for changes, additional information or documentation even after submission of any initial documentation. We shall not be liable for any delay in verifying Your Gventure Communication Number caused by a third party and will not provide credit for any such delays.</li>
        <li>Provision of Gventure Communication Numbers is subject to applicable numbering rules and regulatory practices, which may change or be amended from time to time. We reserve the right to change the terms related to Gventure Communication Numbers accordingly, including without limitation to impose or amend local residency requirements and/or to require the provision of further user information for continued access to defined Gventure Communication Numbers.</li>
        <li>
			<p>Further, We reserve the right to block, cancel or suspend any Gventure Communication Numbers without notice:</p>
            <ul>
                <li>(i) if We determine it is necessary to protect Us, Our customers or third parties from harm, fraud;</li>
                <li>(ii) if required by applicable laws;</li>
                <li>(iii) due to breach of these Terms by You. Further, You may also be liable for charges as mentioned under clause 8.2 of these Terms.</li>
            </ul>
        </li>
        <li>We will acknowledge Your request to port the Gventure Communication Phone Number to a different service provider, provided Your Gventure Communication Phone Number is active at the time of making such request. All requests for number portability shall be processed within 15 (fifteen) working days from the date of the request and no such request will be unreasonably withheld. You acknowledge that We shall not be liable for any delay in the porting of Your number caused by the third party and will not provide credit for any such delays. You acknowledge that We are required to comply with applicable law when processing any porting request and a request may be denied if it is not in compliance with applicable law.</li>
    	<!-- Add other Gventure Communication Phone Numbers sections as needed -->
    </ol>
	
	   <h4>7. FAIR USE POLICY</h4>

    <ol>
        <li>If You have subscribed to an unlimited plan for the Services, then the following terms would be applicable:</li>
        <ul>
            <li>(a) All unlimited plans shall be used only for (i) normal business purposes; (ii) live dialog between two individuals;</li>
            <li>(b) You shall not engage in (i) Trunking or forwarding the Gventure Communication Number to another phone number(s) capable of handling multiple simultaneous calls, or to a private branch exchange (PBX) or a key system;</li>
            <li>(ii) Auto-dialing or “predictive” dialing (i.e., non-manual dialing or using software programs or other means to continuously dial or place out-bound calls;</li>
            <li>(iii) making outgoing calls or sending messages or faxes using a Gventure Communication Number, as CLI via a third party provider unless mutually agreed in writing by the Parties.</li>
        </ul>
        <li>As a part of the user verification process, You may be requested to provide Us the following information for each number: name, ID number, place of residence or business (street, number, zip code, city, country), corresponding IP address, business registration certificate where the User is a legal entity and any other relevant information (the “User Information”). You shall keep the User Information up to date and shall ensure that any User Information provided to Us identifies the true user of that number.</li>
             <li>Upon Our request, You shall submit to Us within 24 hours:</li>
        <ul>
            <li>(i) the User Information; and</li>
            <li>(ii) all other relevant information concerning the services You are providing.</li>
        </ul>
        <p>Further, You shall provide contact details of a representative responsible for handling law enforcement agency requests and responding to Our communications.</p>
        <li>If you have subscribed to the plan which provides unlimited calling service (office phone system plans), all incoming and outgoing calls are subjected to fair usage limit and upon reaching the limit, the user shall be shifted to Call Center Solution plans. In this case, the user might get charged extra for calling after crossing the fair usage limit.</li>
        <li>For office phone system plans, while purchasing the plan you can select the country for which you wish to get minutes benefits, you will get benefits of free minutes (as per fair usages policy) for only one country which you have selected while purchasing the office phone system plan.</li>
    	<!-- Add other Fair Use Policy sections as needed -->
    </ol>
	
	    <h4>8. CHARGES AND PAYMENT</h4>

    <ol>
        <li>
            <h5>Subscription Charges:</h5>
            <p>Unless otherwise mentioned in an Order Form, the Subscription Charges are due in full and payable in advance, in accordance with clause 8.3, when You subscribe to the Services. The Subscription Charges shall be specified on Our Website(s) (Gventure Communication Pricing Plans) or in an Order Form. However, do note that the Subscription Charges are subject to periodic changes/revisions; however, such changes shall be promptly communicated to You in advance.</p>
        </li>

        <li>
            <h5>Other Charges:</h5>
            <p>We may invoice You an administrative fee of up to three hundred (300) USD per complaint of abusive or fraudulent usage of a Gventure Communication Number in Your Account and/or per request for information received from a competent law enforcement authority. This administrative fee constitutes reimbursement for the costs incurred by Us, as determined solely by Us.</p>
        </li>

        <li>
            <h5>Payment:</h5>
            <p>You agree to provide Us with your credit card information for billing purposes (“Payment Method”), and You represent and warrant that you are authorized to use the designated Payment Method and that You authorize Us (or our third-party payment processor) to charge your Payment Method for the total amount of your Subscription Charges. We shall invoice You at the beginning of each month, and all payments are due within 30 days of the receipt of the invoice. We may agree on a different payment method in an Order Form.</p>
        </li>

        <li>
            <h5>Compare Monthly and Annual Payment Plans</h5>
            <p><strong>Monthly Plan:</strong> For a monthly plan, you will be billed monthly for each user account or for our services. Users will be able to add and remove accounts as and when needed. Also, you will only be required to pay for accounts that you have during that month. Importantly, you can cancel service at any time without penalty.</p>
            <p><strong>Annual Plan:</strong> Annual plans require you to commit to purchasing services for a year or longer. However, if you scale your team, you will need to purchase new licenses, which will bring up your monthly rate. Also, under a fixed-term plan, you can reduce licenses or monthly payments only when renewing your plan at the end of the contract. Importantly, unlike flexible plans, if you cancel your subscription before the contract expires, you still have to pay for the full commitment.</p>
			<p><strong>Gventure Communication Pricing :</strong> Below are the standard prices, offerings and terms of Gventure Communication pricing plans. We review and revise them regularly to make them more user-friendly.</p>
			 <h4>Subscription Plans</h4>

             <table class="plan-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Monthly Plan (Flexible Plan)</th>
                    <th>Annual Plan (Fixed-Term Plan)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Commitment</td>
                    <td>None</td>
                    <td>1 year or more of service for licenses purchased at the start of the contract.</td>
                  </tr>
                  <tr>
                    <td>Billing cycle</td>
                    <td>Monthly</td>
                    <td>Annually/Monthly</td>
                  </tr>
                  <tr>
                    <td>Add Users</td>
                    <td>At any time for additional monthly cost.</td>
                    <td>At any time for additional monthly cost.</td>
                  </tr>
                  <tr>
                    <td>Reduce Users</td>
                    <td>At any time for reduced monthly cost.</td>
                    <td>Only when the contract is renewed. Until then, you must pay for all licenses purchased.</td>
                  </tr>
                  <tr>
                    <td>Cancel service</td>
                    <td>At any time (No penalty).</td>
                    <td>Must pay full commitment; even if you cancel early.</td>
                  </tr>
                </tbody>
              </table>

		</li>
		<li>
            <h5>Payment Dispute:</h5>
            <p>In case of any dispute over the Subscription Charges, a request for such dispute shall be raised by You within a period of 7 (seven) days of receipt of the invoice, failing which it shall be deemed that there is no dispute in relation to Your Subscription Charges.</p>
        </li>

        <li>
            <h5>Refunds & Cancellation Fee:</h5>
            <p>Unless otherwise specified in these Terms, all Subscription Charges are non-refundable. No refunds shall be issued for partial use or non-use of the Service(s). Calling credits provided by Us as a part of offer, promotion, or other gestures will not be refunded.</p>
        </li>

        <li>
            <h5>Late Payments/Non-payment of Subscription Charges:</h5>
            <p>You acknowledge that credit card payments are subject to the approval of the card issuer and We will not be liable in any way if a card issuer refuses to accept a payment for any reason. We will notify You in the event We do not receive payment towards Subscription Charges within the due date. We must receive payments within a maximum of ten (10) days from the date of Our notice. If We do not receive payment within the foregoing time period, in addition to the right to other remedies available under law, We may suspend Your access to and use of the Service(s) until We receive Your payment towards the Subscription Charges as specified and/or terminate Your Account.</p>
        </li>

        <li>
            <h5>Other Terms:</h5>
            <p>The Subscription Charges are exclusive of any applicable communications service or telecommunication provider (e.g., carrier) fees or surcharges including internet charges (collectively, “Communication Charges”). You will pay all Communications Charges associated with your use of the Services. Airtime and other measured usage are billed in full-minute increments, and actual airtime and usage are rounded up to the next full-minute increment at the end of each call for billing purposes. If pay per minute billing applies within your plan, we charge a full minute of airtime usage for every fraction of the last minute of airtime used on each call.</p>
        </li>

        <li>
            <h5>Applicable Taxes:</h5>
            <p>Unless otherwise stated, the Subscription Charges do not include any taxes, levies, duties, or similar governmental assessments, including value-added, sales, use, or withholding taxes assessable by any local, state, provincial, or foreign jurisdiction (collectively “Taxes”).</p>
        </li>
		
    </ol>

	   <h4>9. Term, Termination, and Suspension</h4>

		<ol>
			<li><strong>Termination by You:</strong> You may terminate one or more of your Account(s) in the event We materially breach these Terms, provided that You shall provide an advance notice of such breach and afford Us not less than thirty (30) days to cure such breach. In case of such termination We shall, pro-rata, refund the Subscription Charges for the remainder of the Subscription Term.</li>

			<li><strong>Suspension and Termination by Us:</strong> In addition to suspension for late payment or non-payment of Subscription Charges, We may suspend Your access to and use of Your Account or the Service(s) if You are in violation of these Terms. We will notify You if your activities violate these Terms and, at Our sole discretion, provide You with a period of fifteen (15) days (“Cure Period”) to cure or cease such activities. If You fail to cure or cease such activities within said Cure Period or if We believe that such breaches cannot be cured, Your Account shall be terminated. We may also terminate a Trial Period in accordance with clause 3.1. Further, We also reserve the right to terminate Your Account at any time by written notice due to business reasons which shall include discontinuation of the Services.</li>

			<li><strong>Termination for Insolvency:</strong> Notwithstanding anything contained herein, either Party may terminate these Terms with notice if the other Party becomes insolvent, makes or has made an assignment for the benefit of creditors, is the subject of proceedings in voluntary or involuntary bankruptcy instituted on behalf of or against such Party (except for involuntary bankruptcies which are dismissed within sixty (60) days), or has a receiver or trustee appointed for substantially all of its property.</li>
			<li><strong>Effect of Terminating Your Account:</strong> Following the termination of Your Account either by Yourself or by Us, We shall delete all Customer Content in our possession within two (2) years of the date of effective termination (“Retention Period”). Within such Retention Period, You may export the Customer Content by writing to Us at legal@GventureCommunication.com. Further, all Gventure Communication Numbers allocated to You shall be deactivated upon termination of Your Account. Additionally, all unused calling credits shall be cancelled and no refund shall be payable, unless otherwise communicated by Us in writing.</li>
		</ol>

        <h4>10. Confidentiality; Data Privacy and Security</h4>
        <ol>
			<li>If You choose, or are provided with, a user identification code, login, password, or any other piece of information as part of Our security procedures, You must treat such information as confidential. You must not disclose it to any third party. We shall have the right to disable any user identification code or password, whether chosen by You or allocated by Us, at any time, if in Our reasonable opinion, You have failed to comply with any of the provisions of these Terms. We will not be responsible for any activities, including any attempted or actual access or loss of data occurring in Your Account as a result of your non-compliance of obligations under this clause 10.1.</li>

			<li>Confidentiality obligations: Each of the Parties will protect the other’s Confidential Information from unauthorized use, access or disclosure in the same manner as each of the Parties protects its own Confidential Information, and in any event, no less than reasonable care. Except as otherwise expressly permitted pursuant to these Terms, each of the Parties may use the other’s Confidential Information solely to exercise its respective rights and perform its respective obligations under these Terms and shall disclose such Confidential Information solely to those of its respective employees, representatives, and agents who have a need to know such Confidential Information for such purposes and who are bound to maintain the confidentiality of, and not misuse, such Confidential Information. The provisions of this clause shall supersede any non-disclosure agreement by and between the Parties entered prior to these Terms that would purport to address the confidentiality of Customer Content and such agreement shall have no further force or effect with respect to Customer Content.</li>

			<li>Security of Customer Content: We shall use appropriate technical and organizational measures to protect the Customer Content. The measures used are designed to provide a level of security appropriate to the risk of Processing the Customer Content. We shall, without undue delay, notify You of any accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to the Customer Content processed by Us.</li>

			<li>In providing the Services We shall Process Customer Content in accordance with these Terms and Our Privacy Policy to provide, maintain and improve the Services, prevent or address any technical problems, or at Your request in connection with support requests. We will process any Personal Data originating from the EU (including United Kingdom and Switzerland) and USA in accordance with the Data Protection Agreement found at Gventure Communication Data Processing Agreement (DPA).</li>

			<li>You understand and acknowledge that, in connection with the use of the Service by Yourself, Your Users and/or End Users, We Process any Personal Data only on Your behalf and as a data processor.</li>

			<li>You acknowledge and agree that We may access or disclose information about You, Your Account, Users, including Customer Content in order to (a)comply with the law or respond to lawful requests or legal process; (b)prevent any infringement of group companies’ or Our customers’ proprietary rights. Additionally, You agree to fully cooperate and disclose any information required by Us (within 24 hours) pursuant to any request or order from law enforcement authorities.Further, at Our sole discretion, any suspected fraudulent, abusive, or illegal activity by You may be referred to law enforcement authorities.</li>
			<li>We shall reasonably assist You, at Your expense, in meeting Your obligations under applicable data protection laws.</li>

		</ol>
		
		<h4>11. DISCLAIMER OF WARRANTIES</h4>
		<ol>
			<li><h5>Disclaimer of Warranties</h5>
			<p>1. THE SERVICE(S), INCLUDING ALL SERVER AND NETWORK COMPONENTS, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. ALL EXPRESS OR IMPLIED REPRESENTATIONS AND WARRANTIES, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, ARE HEREBY EXCLUDED.</p>
			<p>2. YOU ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE ACCESS TO THE SERVICE(S), WHICH IS PROVIDED OVER THE INTERNET AND VARIOUS TELECOMMUNICATIONS NETWORKS, ALL OF WHICH ARE BEYOND OUR CONTROL, WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR FREE FROM VIRUSES OR OTHER MALICIOUS SOFTWARE.</p>
			</li>
		</ol>

		<h4>12. LIMITATION OF LIABILITY</h4>

		<ol>
			<li>
			<h5>Limitation of Liability</h5>
			<p>1. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL EITHER PARTY BE LIABLE TO ANY PERSON FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COVER OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOST REVENUE, LOST SALES, LOST GOODWILL, LOSS OF USE OR LOST CONTENT, IMPACT ON BUSINESS, BUSINESS INTERRUPTION, LOSS OF ANTICIPATED SAVINGS, LOSS OF BUSINESS OPPORTUNITY) HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, CONTRACT, TORT, WARRANTY, BREACH OF STATUTORY DUTY, NEGLIGENCE OR OTHERWISE, EVEN IF A PARTY HAS BEEN ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES OR COULD HAVE FORESEEN SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR AGGREGATE LIABILITY AND THAT OF OUR AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS AND LICENSORS, RELATING TO THE SERVICE(S), WILL BE LIMITED TO AN AMOUNT EQUAL TO ONE HUNDRED UNITED STATES DOLLARS ($100).</p>
			<p>2. IN JURISDICTIONS WHICH DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, OUR LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</p>
			<p>3. NOTWITHSTANDING ANYTHING ELSE TO THE CONTRARY, WE DISCLAIM ALL LIABILITIES, TO THE MAXIMUM EXTENT PERMITTED BY LAW, WITH RESPECT TO THE SERVICES OFFERED DURING THE TRIAL PERIOD.</p>
		</li>
		</ol>

		<h4>13. INDEMNIFICATION</h4>
		<ol>
			<li>
			<h5>Indemnification</h5>
			<p>1. Indemnification by You: You will indemnify and hold Us harmless against any claim brought by a third party against Us, Our respective employees, officers, directors and agents arising from Your acts or omissions in connection with these Terms provided that (a) We promptly notify You of the threat or notice of such a claim, (b) You will have the sole and exclusive control and authority to select defense attorneys, defend and/or settle any such claim; and (c) We shall fully cooperate with You in connection therewith.</p>
			</li>
		</ol>

		<h4>14. MISCELLANEOUS</h4>
		<ol type="14">
			<li>
			<h5>Miscellaneous</h5>
			<p><strong>1. Assignment:</strong> This Agreement and any rights or obligations hereunder may not be assigned by You without Our prior written consent, whereas We can assign any of its rights and obligations hereunder without Your prior written consent. This Agreement binds, and inures to the benefit of, the Parties and their respective successors and permitted assigns.</p>
			<p><strong>2. Amendment:</strong> We may amend these Terms from time to time, in which case the new Terms will supersede prior versions. We will notify You not less than ten (10) days prior to the effective date of any amendments to these Terms and Your continued use of the Service(s) following the effective date of any such amendment may be relied upon by Us as Your acceptance of any such amendment.</p>
			<p><strong>3. Severability:</strong>  No Waiver: If any provision in these Terms is held by a court of competent jurisdiction to be unenforceable, such provision shall be modified by the court and interpreted so as to best accomplish the original provision to the fullest extent permitted by applicable law, and the remaining provisions of these Terms shall remain in effect. Our non-exercise of any right under or provision of these Terms does not constitute a waiver of that right or provision of These Terms.</p>
			<p><strong>4. Relationship of the Parties:</strong>  The Parties are independent contractors. These Terms do not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship among the Parties.</p>
			<p><strong>5. Survival:</strong>  All clauses which, by their nature are intended to survive, including without limitation Clauses 4 (Intellectual Property Rights), 8 (Charges and Payment), 9 (Term, Termination and Suspension), 10 (Confidentiality; Data Privacy and Security), 11 (Disclaimer of Warranties), 12 (Limitation of Liability), 13 (Indemnification), 14 (Miscellaneous) and 15 (Definitions) shall survive any termination of Our agreement with Yourself regarding the use of the Service(s). Termination shall not limit either Party’s liability for obligations accrued as of or prior to such termination or for any breach of These Terms.</p>
			<p><strong>6. Notices and Consent to Electronic Communications:</strong>  All notices from Us under these Terms may be delivered in writing (i) by a nationally recognized overnight delivery service (“Courier”) or to the contact mailing address provided by You while subscribing to the Service(s); or (ii) electronic mail to the e-mail address provided to Your Account. Our address for a notice is: [●] at [●] with a CC to [●] by electronic mail. All notices shall be deemed to have been given immediately upon delivery by electronic mail, or if otherwise delivered upon receipt or, if earlier, two (2) business days after being deposited in the mail or with a Courier as permitted above.</p>
			<p><strong>7. Publicity Rights:</strong>  You hereby grant Us a royalty-free, worldwide, transferable license to use Your trademark or logo to identify You as Our customer on Our websites and/or marketing collateral.</p>
			<p><strong>8. Export Control:</strong>  Each Party will comply with export control and economic sanctions laws in all applicable jurisdictions that apply directly or indirectly to the Services, including, without limitation, the United States of America. You will obtain all licenses or other authorizations required to export, re-export, or transfer the Services. Each Party represents that it (and, in your case, also Users) is not on any government prohibited/denied/unverified-party, sanctions, debarment, or exclusion list (collectively, “Sanctions Lists”). You will not export, re-export, or transfer the Services to an entity on any Sanctions List without prior U.S. government or other required government authorization. You will (a) immediately discontinue your use of the Services if you are placed on any Sanctions List and (b) remove an User’s access to the Services if such End User becomes placed on any Sanctions List.</p>
			<p><strong>9. Governing Law and Dispute Resolution:</strong>  These Terms shall be governed by the laws of the Republic of India. You hereby expressly agree to submit to the exclusive personal jurisdiction of the courts at Ahmedabad, India. Any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation or validity thereof, including the determination of the scope or applicability of these Terms to arbitrate, shall be first settled by arbitration administered in accordance with the Arbitration and Conciliation Act, 1996 (including any subsequent amendments). The language of the arbitration shall be English and the seat shall be Ahmedabad, India. The dispute shall be resolved by a sole arbitrator who shall be mutually appointed by the Parties. The decision of the sole arbitrator shall be final and binding on the Parties.</p>
			<p><strong>10. Entire Agreement:</strong>  These Terms, together with any Order Forms, constitute the entire agreement, and supersede any and all prior agreements between Us and Yourself with regard to the subject matter hereof. In the event of a conflict between the terms of any Order Form and these Terms, the Terms shall prevail. In case of a conflict between two Order Forms, the Order Form later in date shall prevail.</p>
			<p><strong>11. Force Majeure:</strong>  Notwithstanding anything to the contrary contained elsewhere, We shall not be liable for unavailability of the Service(s) caused by circumstances beyond Our reasonable control, such as, but not limited to, acts of God, acts of government, acts of terror or civil unrest, technical failures beyond Our reasonable control (including, without limitation, inability to access the internet, unauthorized loss, distribution or dissemination of Customer Content), or acts undertaken by third parties, including without limitation, distributed denial of Service attacks.</p>
			</li>
		</ol>
		
		<h4>15. Definitions</h4>
        <p>When used in these Terms with the initial letters capitalized, in addition to terms defined elsewhere in these Terms, the following terms have the following meanings:</p>

        <ul>
            <li><strong>Account:</strong> Means any accounts or instances created by You or on Your behalf for access and use of the Services.</li>
            <li><strong>API:</strong> Means the application programming interfaces developed, enabled by or licensed to Us that permit access to certain functionality provided by the Service(s).</li>
            <li><strong>Gventure Communication Numbers:</strong> Means the phone numbers provided by Us to You as a part of the Services.</li>
            <li><strong>Confidential Information:</strong> Means all information disclosed by one Party to the other Party which is in tangible form and labelled “confidential” (or with a similar legend) or which a reasonable person would understand to be confidential given the nature of the information and circumstances of disclosure. For purposes of These Terms, Customer Content shall be deemed Confidential Information. Notwithstanding the foregoing, Confidential Information shall not include any information which (a) was publicly known and made generally available in the public domain prior to the time of disclosure by the disclosing party; (b) becomes publicly known and made generally available after disclosure by the disclosing party to the receiving party through no action or inaction of the receiving party; (c) is already in the possession of the receiving party at the time of disclosure by the disclosing party as shown by the receiving party’s files and records prior to the time of disclosure; (d) is obtained by the receiving party from a third party without a breach of such third party’s obligations of confidentiality; (e) is independently developed by the receiving party without use of or reference to the disclosing party’s Confidential Information, as shown by documents and other competent evidence in the receiving party’s possession; or (f) is required by law to be disclosed by the receiving party, provided that the receiving party shall, to the extent legally permitted, give the disclosing party written notice of such requirement prior to disclosing so that the disclosing party may seek a protective order or other appropriate relief.</li>
            <li><strong>Customer Content:</strong> Means all electronic data, text, messages, call logs, contact information, personal data or other materials, including without limitation Personal Data of Users and End Users, submitted to the Services by You through Your Account in connection with Your use of the Services.</li>
            <li><strong>Documentation:</strong> Means any written or electronic documentation, images, video, text or sounds specifying the functionalities of the Service(s) provided or made available by Us to You or Your Users through the Service(s) or otherwise.</li>
            <li><strong>End User:</strong> Means any person or entity other than You or Your Users with whom You interact using the Service(s).</li>
            <li><strong>GDPR:</strong> Means the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the Processing of Personal Data and on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation).</li>
            <li><strong>Order Form:</strong> Means any service order form or statement of work specifying the Service(s) subscribed to, particular features and functionalities in the Service(s) that You wish to avail and the Subscription Term.</li>
            <li><strong>Personal Data:</strong> Means data relating to a living individual who is or can be identified either from the data or from the data in conjunction with other information that is in, or is likely to come into, the possession of the data controller.</li>
            <li><strong>Processing/To Process:</strong> Means any operation or set of operations which is performed upon Personal Data, whether or not by automatic Means, such as collection, recording, organization, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, blocking, erasure or destruction.</li>
            <li><strong>Service(s):</strong> Means the cloud-based proprietary VoiP calling platform found at https://GventureCommunication.com/ and any new services that We may introduce as a Service to which You may subscribe to, and any updates, modifications or improvements thereto, including individually and collectively, the API and any Documentation.</li>
            <li><strong>Subscription Charges:</strong> Means all charges associated with Your Account and use of the Services.</li>
            <li><strong>Subscription Term:</strong> Means the period during which You have agreed to subscribe to the Service(s) specified in the relevant Order Form.</li>
            <li><strong>Third-party Service(s):</strong> Shall mean third party application(s) or service(s) integrating with the Service(s) through APIs.</li>
            <li><strong>User:</strong> Means those who are designated users within the Service(s), including an Account administrator, agents and other designated users.</li>
            <li><strong>Website:</strong> Means and any other websites that we own or operate.</li>
        </ul>
 
		
        </div>
        <div class="single-content">
          
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/termsconditions.png" alt="Images">
                </div>
                
                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/termsconditions(2).png" alt="Images">
                </div>

                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/termsconditions(3).png" alt="Images">
                </div>

                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/termsconditions(4).png" alt="Images">
                </div>

                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/termsconditions(5).png" alt="Images">
                </div>

                <div class="brand-logo-item1">
                    <img src="assets/img/Legal/termsconditions(6).png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>