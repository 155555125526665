import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-ai-startup',
  templateUrl: './ai-startup.component.html',
  styleUrls: ['./ai-startup.component.scss']
})
export class AiStartupComponent implements OnInit {
  
  @ViewChild('contactModal') contactModal: ElementRef;

  public contactForm: FormGroup;
  public isposted:number=0;
  public submitted:boolean=false;

  get f() {
    return this.contactForm.controls;
  }

  constructor(private titleService: Title, private metaService: Meta, private fb: FormBuilder, protected http: HttpClient) { 
    this.contactForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: [''],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],      
      message: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // Set the title
    this.titleService.setTitle('Fax Broadcast - Send and receive fax broadcast service online');

    // Set meta tags
    this.metaService.updateTag({ name: 'description', content: 'Easily send a fax to multiple recipients from your computer, tablet, or cell phone. With just a few clicks and a subscription to b2beFax, you can effortlessly set up a bulk fax campaign.' });
    this.metaService.updateTag({ name: 'keywords', content: 'fax to fax, fax services, e fax, e fax online, sending faxes, secure fax, secure fax online, secure fax, fax solutions, broadcast fax, secure electronic fax, fax broadcasting, send fax to, bulk fax service' });
  }

  onSubmit(): void {
    
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    console.log(this.contactForm.value)

    let cdata = JSON.stringify(this.contactForm.value);
  
    this.http.post<any>(environment.Portal3, { "data": cdata }, { headers: { "Content-Type": "application/json" } }).subscribe(res => {
      this.isposted=1;
      console.log(res);
      // Close the modal
      this.closeModal();
    }, err => {
        console.log(err);
        this.isposted=1;
        // Close the modal
        this.closeModal();
    });

  }

  closeModal() {
    const modalElement = this.contactModal.nativeElement;
    modalElement.classList.remove('show');
    modalElement.style.display = 'none';
    modalElement.setAttribute('aria-hidden', 'true');
    modalElement.removeAttribute('aria-modal');
    modalElement.removeAttribute('role');
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (modalBackdrop) {
      modalBackdrop.remove();
    }
  }

  Close(){
    this.isposted=0;
  }
}
