import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    // Set the title
    this.titleService.setTitle('Fax Pricing - Best Online Fax Service Pricing By B2B E-Faxing');

    // Set meta tags
    this.metaService.updateTag({ name: 'description', content: 'The best online fax service pricing by B2B E-Faxing. Find cost-effective solutions for your business communication needs with transparent and competitive fax pricing plans.' });
    this.metaService.updateTag({ name: 'keywords', content: 'Fax Pricing, online fax service, B2B E-Faxing, business communication, pricing plans' });

  }

}
