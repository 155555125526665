
  <div *ngIf="showSeoArea" class="seo-area mt-5">
    <div class="container-fluid">
      <div class="seo-width">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-12">
            <div class="seo-img">
              <img src="assets/img/sale.jpg" width="400px" class="imgs" alt="Images">
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="seo-form">
              <!-- <h2>5 FREE faxes and free Demo</h2> -->
              <h2>5 FREE FAXES & FREE DEMO</h2>
              <p>for free demo  sign up now</p>
          </div>
          <div class="col-lg-2 col-md-12">
            <div class="seo-btn">
              <div class="close-button-container d-flex align-items-end justify-content-end">
                <button (click)="closeSeoArea()" class="close-button" >&times;</button>
              </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 btn">
        <button type="submit" class="default-btn border-radius" [routerLink]="['/sign-up']">Sign Up  <i class='bx bx-plus'></i></button>
     </div>
      </div>
    </div>
  </div>  