import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent }  from '../../../app.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  public form: FormGroup;
  public  resdata:any;
  submitted = false;
  public isposted : number=0;
  
  get f() {
    return this.form.controls;
  }

  constructor(protected http: HttpClient) { }

  ngOnInit(): void {

    this.LoadForm();

  }

  LoadForm(){
    this.form = new FormGroup({
      faxtype: new FormControl('1', Validators.required),
      first_name: new FormControl('', Validators.required),      
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      contact: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    console.log("CData : ", this.form.value);
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let cdata = JSON.stringify(this.form.value);
    if(this.form.value.faxtype==1){
      this.http.post<any>(environment.Portal1, { "data": cdata }, { headers: { "Content-Type": "application/json" } }).subscribe(res => {
        if(res.status==200){
          this.isposted=1;
          this.resdata = res.message;
        }
        else{
          this.isposted=2;
          this.resdata = res.message;
        }        
        console.log(res);
      }, err => {
          console.log(err);
          this.resdata = "Registration Failed! Please contact to support.";
          this.isposted=2;
      });
    }
    else{
      this.http.post<any>(environment.Portal2, { "data": cdata }, { headers: { "Content-Type": "application/json" } }).subscribe(res => {
        if(res.status==200){
          this.isposted=1;
          this.resdata = res.message;
        }
        else{
          this.isposted=2;
          this.resdata = res.message;
        } 
        console.log(res);
      }, err => {
          console.log(err);
          this.resdata = "Registration Failed! Please contact to support.";
          this.isposted=2;
      });
    }
    
  }

  Close(type:number){
    this.isposted=0;
    if(type==1)
      this.LoadForm();
  }

}
